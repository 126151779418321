/*global History, Modernizr, FastClick, alert*/
/*jshint sub:true*/

window.main = window.main || {};

//General

var siteName = 'Sofie Coreynen';
var pageTitle;

var $htmlbody = $('html, body');
var $body = $('body');

var pageOpen = false;

var ww = $(window).width();
var wh = $(window).height();
var dw = $(document).width();
var dh = $(document).height();

var slider;

//Components

var $header = $('.js-header');
var $main = $('.js-main');
var $btnToggleSound = $('.js-toggle-sound');
var btnToggleSound = '.js-toggle-sound';
var $btnClosePages = $('.js-close-pages');
var btnClosePages = '.js-close-pages';
var $btnTogglePage = $('.js-toggle-page');
var btnTogglePage = '.js-toggle-page';
var $btnLinkSlide = $('.js-link-slide');
var btnLinkSlide = '.js-link-slide';
var $thumbnail = $('.js-thumbnail');
/*
var $btnToggleIndex = $('.js-toggle-index');
var btnToggleIndex = '.js-toggle-index';
var $btnToggleOverview = $('.js-toggle-overview');
var btnToggleOverview = '.js-toggle-overview';
*/

// Checks

var isHomepage = false;
var isTouchdevice = false;

main.init = function() {

  objectFitImages();

  main.initEvents();
  main.initInactivityMonitor();
  main.setLayout();

  if ( $('.royalSlider').length ) {
		main.initSlider();
	}

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isTouchdevice = true;
    $body.addClass('is-touch');
    $body.addClass('is-mobile-device');
  }

  document.addEventListener('touchmove', function (event) {
    if (event.scale !== 1) { event.preventDefault(); }
  }, false);

  var lastTouchEnd = 0;
  document.addEventListener('touchend', function (event) {
    var now = (new Date()).getTime();
    if (now - lastTouchEnd <= 300) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  }, false);

  $(window).on('load', function() {
    main.setLayout();
  });

  $(window).on('resize', function() {
    main.setLayout();
  });

};

main.initInactivityMonitor = function() {

  var screensaverInterval = null;
  var t;
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer; // catches touchscreen presses
  window.onclick = resetTimer; // catches touchpad clicks
  window.onscroll = resetTimer; // catches scrolling with arrow keys
  window.onkeypress = resetTimer;

  function startScreensaver() {
    if(pageOpen == false) {
      $body.addClass('page-is-inactive');
    }
  }

  function resetTimer() {
    $body.removeClass('page-is-inactive');
    clearTimeout(t);
    t = setTimeout(startScreensaver, 2000); // time is in milliseconds
  }

};

main.initEvents = function() {

  $btnClosePages.on('click', function(e) {
    e.preventDefault();
    main.closePages();
  });

  $btnToggleSound.on('click', function(e) {
    e.preventDefault();
    main.toggleSound();
  });

  $btnTogglePage.on('click', function(e) {
    e.preventDefault();
    main.togglePage($(this).attr('href'));
  });

  $btnLinkSlide.on('click', function(e) {
    e.preventDefault();
    main.closePages();
    main.findAndGoToSlide($(this).data('project'),$(this).data('slide'));
  });

  $thumbnail.hover(
    function() {
      $(this).addClass('is-active');
      $(this).siblings('[data-project="'+$(this).data('project')+'"][data-slide="'+$(this).data('slide')+'"]').addClass('is-active');
    }, function() {
      $(this).removeClass('is-active');
      $(this).siblings('[data-project="'+$(this).data('project')+'"][data-slide="'+$(this).data('slide')+'"]').removeClass('is-active');
    }
  );

};

main.closePages = function() {

  pageOpen = false;
  $body.removeClass('overlay-page-is-open');

  $('.js-overlay-page.is-active').removeClass('is-active');
  $('.js-main').addClass('is-active');

};

main.togglePage = function(page) {

  pageOpen = true;
  $body.addClass('overlay-page-is-open');

  $('.js-overlay-page.is-active').removeClass('is-active');
  $('.js-main.is-active').removeClass('is-active');
  $('.js-current-page').html($('nav ul li a[rel="'+page+'"]').text());

  setTimeout(function(){
    $('.js-overlay-page[data-page="'+page+'"]').toggleClass('is-active');
    $('nav ul li a[rel="'+page+'"]').parent().toggleClass('is-active');
  },440);

};

main.findAndGoToSlide = function(project,slideNumber) {

  var slideIndex;
  $.each(slider.slides, function(index, value) {
      if(value.content.attr('data-project') == project && value.content.attr('data-slide') == slideNumber) {
        slideIndex = index;
        return false;
      }
  });

  slider.goTo(slideIndex);

};


main.initSlider = function() {

  setTimeout(function() {
      $('.js-main').addClass('is-active');
  },220);

  setTimeout(function() {
      $('.js-header-content').addClass('is-active');
  },440);

  $('.royalSlider').css('overflow', 'visible');
  $(".royalSlider").royalSlider({
    loop: true,
    keyboardNavEnabled: true,
    autoScaleSlider: false,
    autoHeight: false,
    imageScalePadding: 0,
    imageScaleMode: 'none',
    imageAlignCenter: false,
    slidesSpacing: 0,
    globalCaption: true,
    navigateByClick: false,
    arrowsAutoHide: true,
    arrows: true,
    sliderDrag: false,
    sliderTouch: false,
    transitionSpeed: 0,
    numImagesToPreload: 2,
    fadeinLoadedSlide: true,
    deeplinking: {
    		// deep linking options go gere
    		enabled: true,
        change: true,
    		prefix: ''
    	}
  });

  $.fn.lazysizesRoyal = function(){
      var addPrevNextPreload = function(){
          var setPreload;
          var slider = $(this).data('royalSlider');

          if(!slider){return;}
          setPreload = function(){
              slider.currSlide.holder
                  .next()
                  .add(slider.currSlide.holder.prev())
                  .find('.lazyload')
                  .addClass('lazypreload')
              ;
          };
          setPreload();
          slider.ev.on('rsAfterSlideChange', setPreload);
      };
      return this.each(addPrevNextPreload);
  };
  
  $('.royalSlider').lazysizesRoyal();
  slider = $(".royalSlider").data('royalSlider');

  /*
  BEGINNING OF CODE FOR LAZYLOADING SLIDES
  */
  /*
  var updateCurrentImage = function(slideContent) {
  	if(slideContent.attr('data-image-appended')) {
  		return;
  	}

  	var linkElement = slideContent.is('a') ? slideContent : slideContent.find('a').eq(0);
  	var img = new Image();
  	img.setAttribute('srcset', linkElement.attr('data-srcset') );
  	img.src = linkElement.attr('href');
    img.setAttribute('alt', linkElement.text() );
    linkElement.html(img); // append image in anchor
    slideContent.attr('data-image-appended', 'true');
  };
  slider.ev.on('rsAfterContentSet', function(e, slideObject) {
  	updateCurrentImage(slideObject.content);
  });
  $('.royalSlider .rsSlide').each(function() {
  	updateCurrentImage( $(this).children().first() );
  });
  */

  $('.rsGCaption').prependTo('.c-header__content');
  var videoIsPlaying = false;

  $(".royalSlider").on('mousemove', function(e){
  	if (e.pageX < $(window).width()/2){
  		$(".royalSlider").removeClass('hover-next');
  		$(".royalSlider").addClass('hover-prev');
  	} else if (e.pageX > $(window).width()/2) {
  		$(".royalSlider").removeClass('hover-prev');
  		$(".royalSlider").addClass('hover-next');
  	}
  });

  $('.royalSlider').on('click', function(e){
  	if (e.pageX < $(window).width()/2){
  		slider.prev();
  	} else if (e.pageX > $(window).width()/2) {
  		slider.next();
  	}
  });

  slider.ev.on('rsAfterSlideChange', function() {
    if (slider.currSlide.content.children('.o-slide--video').length > 0) {
      //If the slide has a video, trigger to play it
      slider.currSlide.content.children('.o-slide--video').find('video').trigger('play');
      videoIsPlaying = true;
      $btnToggleSound.addClass('is-visible');
   } else {
     if(videoIsPlaying == true) {
      $('video').trigger('pause');
     }
     $btnToggleSound.removeClass('is-visible');
   }

   images = slider.slides;

    //console.log(slider.currSlide);
    //console.log($('.rsActiveSlide').children());
  });

};

var my_mute = false;

main.toggleSound = function() {

  function toggleMute(elem) {
      elem.muted = !elem.muted;
      if(elem.muted == false) {
        $btnToggleSound.html('Sound on');
      }else {
        $btnToggleSound.html('Sound off');
      }
  }

  var videos = document.querySelectorAll("video"),
      audios = document.querySelectorAll("audio");

  [].forEach.call(videos, function(video) { toggleMute(video); });
  [].forEach.call(audios, function(audio) { toggleMute(audio); });

  my_mute = !my_mute;


};

main.setLayout = function() {

  ww = $(window).width();
  wh = $(window).height();
  dw = $(document).width();
  dh = $(document).height();

};

$(main.init);
